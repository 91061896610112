import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box, IconButton, TableCell, TableRow, Tooltip,
} from '@mui/material';
import { ForwardToInbox, Error } from '@mui/icons-material';
import {
  CopyLinkIcon,
  DownloadIcon,
  TickIcon,
} from '../../../../../../../../../shared/icons';
import { TextAndIconWrapper } from '../../../../Settings/UsageAndBilling/styled';
import { Order } from '../../../../../../../types/sales';
import { useTypedSelectorCreator } from '../../../../../../../../../shared/hooks/useTypedSelector';
import {
  getFormattedDateOrDateName,
  getFormattedTime,
} from '../../../../../../../../../shared/utils';
import { TextLimited } from '../../../../Sales/styled';
import { downloadAs, getCSV } from '../../../../../../../utils/csv';
import { useResendDownloadLink } from './hooks/useResendDownloadLink';
import * as variables from '../../../../../../../../../constants/styles';
import { useCopyValue } from '../../../../../../../../../shared/components/CommonCopyInput/useCopyValue';
import { PinkLoader } from '../../../../../../../../../shared/components/Loaders/PinkLoader/PinkLoader';

type OrderRowProps = {
  row: Order;
  galleryName: string;
};

const getSendIcon = (isLoading: boolean, isSuccess: boolean, error: string) => {
  if (isLoading) {
    return <PinkLoader size="xs" />;
  }
  if (isSuccess) {
    return (
      <Box
        sx={{ width: '24px', height: '24px' }}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <TickIcon
          sx={{
            width: '16px',
            height: '16px',
            color: variables.colorPink,
          }}
        />
      </Box>
    );
  }
  if (error) {
    return <Error sx={{ color: variables.colorError }} />;
  }
  return <ForwardToInbox sx={{ color: variables.colorPink }} />;
};

export const OrderRow: React.FC<OrderRowProps> = ({ row, galleryName }) => {
  const { t } = useTranslation('sales');
  const currentUser = useTypedSelectorCreator(
    (state) => state.creator.currentUser,
  );
  const {
    isLoading, isSuccess, error, handleResendDownloadLink,
  } = useResendDownloadLink();
  const {
    isCopied, handleCopy,
  } = useCopyValue();

  const date = getFormattedDateOrDateName(
    row.createOrderTimestamp,
    currentUser?.dateFormat || '',
    currentUser?.timezone,
  );
  const time = getFormattedTime(
    row.createOrderTimestamp,
    currentUser?.timezone,
    currentUser?.dateFormat || '',
  );

  const handleDownload = () => {
    const csvContent = getCSV(row);
    downloadAs(csvContent, galleryName);
  };
  const handleCopyArchiveLink = (e: React.MouseEvent<HTMLButtonElement>) => {
    const link = `${process.env.REACT_APP_BASE_MEDIA_URL}/${row.id}.zip`;
    handleCopy(e, link);
  };

  return (
    <TableRow
      key={row.id}
      sx={{
        '&:last-child td, &:last-child th': { border: 0 },
        '&:hover': {
          '& .action-button': {
            visibility: 'visible',
          },
        },
      }}
      hover
    >
      <TableCell
        sx={{
          paddingLeft: '10px',
          paddingBottom: '8px',
          paddingTop: '8px',
          borderBottom: '0px',
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
        }}
        align="left"
      >
        <Tooltip
          slotProps={{
            tooltip: {
              sx: {
                backgroundColor: '#fff',
                boxShadow: '2px 2px 3px #e7e7e7',
                borderRadius: '4px',
                padding: '6px 8px',
                fontSize: '10px',
                color: 'rgba(5, 27, 44, 0.8)',
              },
            },
          }}
          title="Copy archive link"
          placement="top"
        >
          <IconButton
            className="action-button"
            sx={{
              visibility: 'hidden',
              position: 'absolute',
              left: '-10px',
              '&.MuiButtonBase-root': {
                borderRadius: '50%',
                padding: '4px',
              },
            }}
            onClick={handleCopyArchiveLink}
          >
            {isCopied ? (
              <Box
                sx={{ width: '24px', height: '24px' }}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <TickIcon
                  sx={{
                    width: '16px',
                    height: '16px',
                    color: variables.colorPink,
                  }}
                />
              </Box>
            ) : (
              <CopyLinkIcon sx={{ color: variables.colorPink }} />
            )}
          </IconButton>
        </Tooltip>
        <TextLimited>{row.email}</TextLimited>
      </TableCell>
      <TableCell
        sx={{ borderBottom: '0px', paddingBottom: '8px', paddingTop: '8px' }}
        align="left"
      >
        {date}
      </TableCell>
      <TableCell
        sx={{
          whiteSpace: 'pre',
          borderBottom: '0px',
          paddingBottom: '8px',
          paddingTop: '8px',
        }}
        align="left"
      >
        {time}
      </TableCell>
      <TableCell
        sx={{
          textTransform: 'capitalize',
          borderBottom: '0px',
          paddingBottom: '8px',
          paddingTop: '8px',
        }}
        align="left"
      >
        {row.status}
      </TableCell>
      <TableCell
        sx={{ borderBottom: '0px', paddingBottom: '8px', paddingTop: '8px' }}
        align="center"
      >
        $
        {row.amount / 100}
      </TableCell>
      <TableCell
        align="center"
        sx={{
          borderBottom: '0px',
          paddingBottom: '8px',
          paddingTop: '8px',
          position: 'relative',
          paddingRight: '40px',
        }}
      >
        <TextAndIconWrapper>
          {row.files.length ? (
            <button
              type="button"
              onClick={handleDownload}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <DownloadIcon />
            </button>
          ) : (
            '-'
          )}
        </TextAndIconWrapper>
        <Tooltip
          title={error || 'Resend delivrable link for downloading'}
          placement="top"
          slotProps={{
            tooltip: {
              sx: {
                backgroundColor: '#fff',
                boxShadow: '2px 2px 3px #e7e7e7',
                borderRadius: '4px',
                padding: '6px 8px',
                fontSize: '10px',
                color: error ? variables.colorError : 'rgba(5, 27, 44, 0.8)',
              },
            },
          }}
        >
          <IconButton
            disabled={isLoading || isSuccess}
            className="action-button"
            sx={{
              visibility: 'hidden',
              '&.MuiButtonBase-root': {
                position: 'absolute',
                right: '0px',
                top: '50%',
                transform: 'translate(-50%, -50%)',
                borderRadius: '50%',
                padding: '4px',
              },
            }}
            onClick={() => handleResendDownloadLink(row.id)}
          >
            {getSendIcon(isLoading, isSuccess, error)}
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};
