import React from 'react';
import { ChangeColorButton } from '../../../../../../../../shared/components/GradientCoverWithActions/styled';
import { InstantDownload, HeartIcon } from '../../../../../../../../shared/icons';
import { BaseColors, ClientGalleryFile } from '../../../../../../../../shared/types';
import { getBaseColor } from '../../../../../../../../shared/utils/style/getBaseColor';
import { useLike } from '../../../../../../hooks/buttons/useLike';
import { LikeAndInstDownloadableWrapper } from './styled';
import { PinkLoader } from '../../../../../../../../shared/components/Loaders/PinkLoader/PinkLoader';

type InstantlyDownloadableOrLikeProps = {
  instantlyDownloadable: boolean;
  disabledAll: boolean;
  fileId: string;
  iconsColor?: BaseColors;
  isFileLiked: boolean;
  file: ClientGalleryFile;
};

export const InstantlyDownloadableOrLike: React.FC<
  InstantlyDownloadableOrLikeProps
> = ({
  instantlyDownloadable, disabledAll, fileId, iconsColor, isFileLiked, file,
}) => {
  const { handleLike, isLoadingFav } = useLike(isFileLiked);

  return (
    <LikeAndInstDownloadableWrapper>
      <span id="inst-downloadable">
        {instantlyDownloadable && <InstantDownload />}
      </span>
      <LikeAndInstDownloadableWrapper>
        <ChangeColorButton
          isClicked={isFileLiked}
          disabled={!!disabledAll || isLoadingFav}
          type="button"
          onClick={() => handleLike(file)}
          id="client-like-button-step"
          baseColor={iconsColor ? getBaseColor(iconsColor) : ''}
        >
          {isLoadingFav ? <PinkLoader size="xs" /> : <HeartIcon />}
        </ChangeColorButton>
      </LikeAndInstDownloadableWrapper>
    </LikeAndInstDownloadableWrapper>
  );
};
