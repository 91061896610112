import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import {
  fetchGetOrdersByCreatorId,
  fetchGetOrdersByGalleryId,
} from './ordersThunk';
import {
  GalleryIdName,
  GetOrdersResponse,
  Order,
  OrderResponse,
} from '../../types/sales';
import { GalleryFileBase, GalleryFileSettings, GallerySectionFields } from '../../../../shared/types';

const selector = (orders: OrderResponse[]) => orders;

export const selectOrders = createSelector<(typeof selector)[],
  Order[]
  >(selector, (orders: OrderResponse[]) => {
    const res: Order[] = [];
    orders.forEach((order) => {
      let files: (GalleryFileBase & GalleryFileSettings & GallerySectionFields)[] = [];
      let freeFiles: (GalleryFileBase & GalleryFileSettings & GallerySectionFields)[] = [];
      order.files.forEach((item) => {
        files = [...files, ...item.files];
      });
      order.limitedFreeFiles.forEach((item) => {
        freeFiles = [...freeFiles, ...item.files];
      });

      const obj = {
        ...order,
        files,
        limitedFreeFiles: freeFiles,
        galleryId: (order.galleryId as GalleryIdName)?.id || order.galleryId,
        name: (order.galleryId as GalleryIdName)?.name || '',
      };

      res.push(obj);
    });
    return res;
  });

type InitialState = {
  loading: boolean;
  error: string | null;
  ordersByCreatorId: OrderResponse[];
  ordersByGalleryId: OrderResponse[];
  total: number;
};

export const initialState: InitialState = {
  ordersByCreatorId: [],
  ordersByGalleryId: [],
  loading: true,
  error: null,
  total: 0,
};

const ordersSlice = createSlice({
  name: 'ordersSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchGetOrdersByGalleryId.fulfilled,
      (state, action: PayloadAction<GetOrdersResponse>) => {
        state.error = null;
        state.loading = false;
        state.ordersByGalleryId = action.payload.data;
        state.total = action.payload.count;
      },
    );
    builder.addCase(
      fetchGetOrdersByGalleryId.rejected,
      (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.loading = false;
      },
    );
    builder.addCase(fetchGetOrdersByGalleryId.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchGetOrdersByCreatorId.fulfilled,
      (state, action: PayloadAction<GetOrdersResponse>) => {
        state.error = null;
        state.loading = false;
        state.ordersByCreatorId = action.payload.data;
        state.total = action.payload.data?.length ? action.payload.count : 0;
      },
    );
    builder.addCase(
      fetchGetOrdersByCreatorId.rejected,
      (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.loading = false;
      },
    );
    builder.addCase(fetchGetOrdersByCreatorId.pending, (state) => {
      state.loading = true;
    });
  },
});

export default ordersSlice.reducer;
