import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CommentPutRes,
} from '../../../../shared/types/client';
import { fetchGetClientGallery, fetchSignInClient, fetchCheckPassGallery } from './signInClientThunk';
import { ErrorStatusAndMessage } from '../../../../shared/types/httpError';
import { ClientGalleryGet } from '../../../../shared/types/clientGallery';
import { GalleryCreatorSocialMedia } from '../../../../shared/types/commonGallery';
import { FileBySection, FileBySectionPut } from '../../../../shared/types';

// eslint-disable-next-line max-len
const selectGallery = (gallery: ClientGalleryGet | null, freeFiles: FileBySection[]) => ({ gallery, freeFiles });

export const remainFilesInSections = createSelector(
  [selectGallery],
  (state) => {
    const res = new Map();
    state.gallery?.sections.forEach((item) => {
      let remainFreeFiles = item.freeFileLimit || 0;
      state.freeFiles.forEach((freeItem) => {
        if (freeItem.sectionId === item.id) {
          remainFreeFiles -= 1;
        }
      });
      res.set(item.id, remainFreeFiles);
    });
    return res;
  },
);

type initialState = {
  isSignIn: boolean;
  gallery: ClientGalleryGet | null;
  socialMedia: GalleryCreatorSocialMedia | null;
  clientEmail: string;
  loading: boolean;
  checkLoading: boolean;
  error: ErrorStatusAndMessage | null;
  wasWatched: boolean;
  hasPassword: boolean;
};

export const initialState: initialState = {
  isSignIn: false,
  gallery: null,
  clientEmail: '',
  checkLoading: false,
  loading: true,
  error: null,
  wasWatched: false,
  socialMedia: null,
  hasPassword: false,
};

const signInClientSlice = createSlice({
  name: 'signInClient',
  initialState,
  reducers: {
    setWasWatched(state) {
      state.wasWatched = true;
    },
    setClientIsNotSignIn(state) {
      state.isSignIn = false;
      state.gallery = null;
      state.clientEmail = '';
      state.loading = false;
      state.error = null;
    },
    likeHandle(state, action: PayloadAction<FileBySectionPut[]>) {
      state.gallery?.sections.forEach((section) => {
        section.files.forEach((file) => {
          if (action.payload.find((item) => item.file === file.id)) {
            file.isFavourited = !file.isFavourited;
          }
        });
        section.ideas.forEach((idea) => {
          if (action.payload.find((item) => item.file === idea.id)) {
            idea.isFavourited = !idea.isFavourited;
          }
        });
      });
    },
    cartHandle(state, action: PayloadAction<{
      cartFiles: FileBySectionPut[];
      limitedFreeCartFiles: Array<FileBySectionPut>;
      type: 'delete' | 'add';
      notIncreaseFreeFiles?: boolean
  }>) {
      state.gallery?.sections.forEach((section) => {
        const current = action.payload.limitedFreeCartFiles.filter(
          (item) => item.sectionId === section.id,
        );
        if (!action.payload.notIncreaseFreeFiles) {
          if (action.payload.type === 'add') {
            section.freeFilesRemaining! -= current?.length || 0;
          } else {
            section.freeFilesRemaining! += current?.length || 0;
          }
        }
      });
      state.gallery?.sections.forEach((section) => {
        section.files.forEach((file) => {
          if (action.payload.cartFiles.find((item) => item.file === file.id)) {
            file.isInCart = !file.isInCart;
          }
          if (action.payload.limitedFreeCartFiles.find((item) => item.file === file.id)) {
            file.isLimitedFreeFile = !file.isLimitedFreeFile;
          }
        });
        section.ideas.forEach((idea) => {
          if (action.payload.cartFiles.find((item) => item.file.includes(idea.id))) {
            idea.isInCart = !idea.isInCart;
          }
          if (action.payload.limitedFreeCartFiles.find((item) => item.file.includes(idea.id))) {
            idea.isLimitedFreeFile = !idea.isLimitedFreeFile;
          }
        });
      });
    },
    addCommentHandle(state, action: PayloadAction<CommentPutRes>) {
      state.gallery?.sections.forEach((section) => {
        section.files.forEach((file) => {
          if (action.payload.fileId === file.id) {
            file.comments = [
              ...(file.comments || []),
              {
                id: action.payload.messageId,
                text: action.payload.text,
                date: action.payload.date,
                creatorMessage: false,
                // email: state.clientEmail,
              },
            ];
          }
        });
        section.ideas.forEach((idea) => {
          if (action.payload.fileId === idea.id) {
            idea.comments = [
              ...(idea.comments || []),
              {
                id: action.payload.messageId,
                text: action.payload.text,
                date: action.payload.date,
                creatorMessage: false,
                // email: state.clientEmail,
              },
            ];
          }
        });
      });
    },
    deleteCommentHandle(
      state,
      action: PayloadAction<{ fileId: string; commentId: string }>,
    ) {
      state.gallery?.sections.forEach((section) => {
        section.files.forEach((file) => {
          if (action.payload.fileId === file.id) {
            file.comments = file.comments?.filter(
              (item) => item.id !== action.payload.commentId,
            );
          }
        });
        section.ideas.forEach((idea) => {
          if (action.payload.fileId === idea.id) {
            idea.comments = idea.comments?.filter(
              (item) => item.id !== action.payload.commentId,
            );
          }
        });
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchSignInClient.fulfilled,
      (state) => {
        state.isSignIn = true;
        state.error = null;
        state.loading = false;
      },
    );
    builder.addCase(
      fetchSignInClient.rejected,
      (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.loading = false;
        state.clientEmail = '';
      },
    );
    builder.addCase(fetchSignInClient.pending, (state) => {
      state.loading = true;
      state.clientEmail = '';
      state.error = null;
    });
    builder.addCase(
      fetchGetClientGallery.fulfilled,
      (
        state,
        action: PayloadAction<{
          gallery: ClientGalleryGet;
          email: string;
          socMed: GalleryCreatorSocialMedia;
          firstVisit: boolean;
        }>,
      ) => {
        const {
          email, gallery, socMed, firstVisit,
        } = action.payload;
        state.isSignIn = true;
        state.error = null;
        state.loading = false;
        state.gallery = gallery;
        state.clientEmail = email;
        state.wasWatched = !firstVisit;
        state.socialMedia = socMed;
      },
    );
    builder.addCase(
      fetchGetClientGallery.rejected,
      (state, action) => {
        // state.error = action.payload;
        state.loading = false;
        state.clientEmail = '';
      },
    );
    builder.addCase(fetchGetClientGallery.pending, (state) => {
      state.loading = true;
      state.clientEmail = '';
      state.error = null;
    });
    builder.addCase(
      fetchCheckPassGallery.fulfilled,
      (
        state,
        action: PayloadAction<{
          password: boolean;
        }>,
      ) => {
        const {
          password,
        } = action.payload;
        state.checkLoading = false;
        state.hasPassword = password;
      },
    );
    builder.addCase(fetchCheckPassGallery.pending, (state) => {
      state.checkLoading = true;
      state.clientEmail = '';
    });
    builder.addCase(fetchCheckPassGallery.rejected, (state, action: PayloadAction<any>) => {
      // state.error = action.payload;
      state.checkLoading = false;
      // state.clientEmail = '';
    });
  },
});

export const {
  likeHandle,
  cartHandle,
  addCommentHandle,
  deleteCommentHandle,
  setClientIsNotSignIn,
  setWasWatched,
} = signInClientSlice.actions;

export default signInClientSlice.reducer;
