import React from 'react';
import { useTranslation } from 'react-i18next';
import { TableCell, TableRow } from '@mui/material';
import { Order } from '../../../../../types/sales';
import { useTypedSelectorCreator } from '../../../../../../../shared/hooks/useTypedSelector';
import { DownloadIcon } from '../../../../../../../shared/icons';
import {
  getFormattedDateOrDateName,
  getFormattedTime,
} from '../../../../../../../shared/utils';
import { TextAndIconWrapper } from '../../Settings/UsageAndBilling/styled';
import { TextLimited } from '../styled';
import { downloadAs, getCSV } from '../../../../../utils/csv';

type OrderTableRowProps = {
  row: Order;
  galleryName: string;
};

export const OrderTableRow: React.FC<OrderTableRowProps> = ({
  row,
  galleryName,
}) => {
  const { t } = useTranslation('sales');
  const currentUser = useTypedSelectorCreator(
    (state) => state.creator.currentUser,
  );
  // eslint-disable-next-line max-len
  const date = getFormattedDateOrDateName(
    row.createOrderTimestamp,
    currentUser?.dateFormat || '',
    currentUser?.timezone,
  );
  const time = getFormattedTime(
    row.createOrderTimestamp,
    currentUser?.timezone,
    currentUser?.dateFormat || '',
  );

  const handleDownload = () => {
    const csvContent = getCSV(row);
    downloadAs(csvContent, galleryName);
  };

  return (
    <TableRow
      key={row.id}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell
        sx={{ paddingLeft: '0px', paddingBottom: '0px', borderBottom: '0px' }}
        component="th"
        scope="row"
      >
        {galleryName}
      </TableCell>
      <TableCell
        sx={{ borderBottom: '0px', paddingBottom: '0px' }}
        align="left"
      >
        <TextLimited>{row.email}</TextLimited>
      </TableCell>
      <TableCell
        sx={{ borderBottom: '0px', paddingBottom: '0px' }}
        align="left"
      >
        {date}
      </TableCell>
      <TableCell
        sx={{ whiteSpace: 'pre', borderBottom: '0px', paddingBottom: '0px' }}
        align="left"
      >
        {time}
      </TableCell>
      <TableCell
        sx={{
          textTransform: 'capitalize',
          borderBottom: '0px',
          paddingBottom: '0px',
        }}
        align="left"
      >
        {row.status}
      </TableCell>
      <TableCell
        sx={{ borderBottom: '0px', paddingBottom: '0px' }}
        align="center"
      >
        $
        {row.amount / 100}
      </TableCell>
      <TableCell
        align="center"
        sx={{ paddingRight: '0px', borderBottom: '0px', paddingBottom: '0px' }}
      >
        <TextAndIconWrapper>
          {row.files.length ? (
            <button
              type="button"
              onClick={handleDownload}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <DownloadIcon />
            </button>
          ) : (
            '-'
          )}
        </TextAndIconWrapper>
      </TableCell>
    </TableRow>
  );
};
