import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ClientGalleryFile, ViewCreator, ViewsCreator } from '../../../../../shared/types/commonGallery';
import {
  fetchCreatorAddComment,
  fetchCreatorDeleteComment,
  fetchDeleteCreatorViews,
  fetchGetCreatorInteractions,
} from './interactionsCreatorThunk';
import { CreatorInteractionsGet } from '../../../../../shared/types/interactions/interactions';
import {
  CreatorCommentReturnResPut,
  CreatorCommentsByFile,
  ErrorType,
  FavouritesCartResItem,
} from '../../../../../shared/types';

type initialState = {
  favourites: FavouritesCartResItem[];
  views: ViewsCreator;
  downloads: FavouritesCartResItem[];
  comments: CreatorCommentsByFile[];
  loadingGet: boolean;
  loadingDelete: boolean;
  loadingPut: boolean;
  error: ErrorType;
};

export const initialState: initialState = {
  favourites: [],
  views: {
    count: 0,
    viewers: [],
  },
  downloads: [],
  comments: [],
  loadingGet: false,
  loadingDelete: false,
  loadingPut: false,
  error: null,
};

const interactionsCreatorSlice = createSlice({
  name: 'interactionsCreatorSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchGetCreatorInteractions.fulfilled,
      (state, action: PayloadAction<CreatorInteractionsGet>) => {
        state.error = null;
        state.loadingGet = false;
        const favs: FavouritesCartResItem[] = [];
        const views: ViewsCreator = { count: 0, viewers: [] };
        const downloads: FavouritesCartResItem[] = [];
        const comments: CreatorCommentsByFile[] = [];
        views.count += action.payload.interactions.length;

        action.payload.interactions.forEach((interaction) => {
          interaction.favourites.forEach((fav) => {
            favs.push({
              ...fav.file as ClientGalleryFile, sectionId: fav.sectionId, email: interaction.email,
            });
          });
          interaction.downloads.forEach((download) => {
            downloads.push({
              ...download.file as ClientGalleryFile,
              sectionId: download.sectionId,
              email: interaction.email,
            });
          });
          views.viewers = [
            ...views.viewers,
            {
              interactionId: interaction._id,
              email: interaction.email,
              lastVisitDate: interaction.lastVisitDate,
            },
          ];
          comments.push({
            interactionId: interaction._id,
            email: interaction.email,
            messages: interaction.comments,
          });
        });
        state.comments = comments;
        state.downloads = downloads;
        state.favourites = favs;
        state.views = views;
      },
    );
    builder.addCase(
      fetchGetCreatorInteractions.rejected,
      (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.loadingGet = false;
      },
    );
    builder.addCase(fetchGetCreatorInteractions.pending, (state) => {
      state.loadingGet = true;
    });
    builder.addCase(
      fetchDeleteCreatorViews.fulfilled,
      (state, action: PayloadAction<{
        galleryId: string;
        email: string;
        interactionId: string;
        view: ViewCreator,
      }>) => {
        state.error = null;
        state.loadingDelete = false;
        state.views.count -= 1;
        state.views.viewers = state.views.viewers.filter(
          (item) => item.email !== action.payload.email
          && item.interactionId !== action.payload.interactionId,
        );
      },
    );
    builder.addCase(
      fetchDeleteCreatorViews.rejected,
      (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.loadingDelete = false;
      },
    );
    builder.addCase(fetchDeleteCreatorViews.pending, (state) => {
      state.loadingDelete = true;
    });
    builder.addCase(
      fetchCreatorAddComment.fulfilled,
      (state, action: PayloadAction<CreatorCommentReturnResPut>) => {
        state.error = '';
        state.loadingPut = false;
        const {
          fileId, interactionId, comment, email, sectionId,
        } = action.payload;

        state.comments.forEach((item) => {
          if (
            item.interactionId === interactionId
            && item.email === email
          ) {
            item.messages.forEach((itemSecond) => {
              if (itemSecond.fileId === fileId) {
                itemSecond.messages.push(comment);
              }
            });
          }
        });
      },
    );
    builder.addCase(
      fetchCreatorAddComment.rejected,
      (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.loadingPut = false;
      },
    );
    builder.addCase(fetchCreatorAddComment.pending, (state) => {
      state.loadingPut = true;
      state.error = '';
    });
    builder.addCase(fetchCreatorDeleteComment.fulfilled, (state, action: PayloadAction<{
      commentId: string,
      sectionId: string,
      fileId: string,
      interactionId: string,
      email: string,
      galleryId: string,
    }>) => {
      const {
        commentId, sectionId, fileId, interactionId, email, galleryId,
      } = action.payload;
      state.comments.forEach((item) => {
        if (
          item.interactionId === interactionId
          && item.email === email
        ) {
          item.messages.forEach((itemSecond) => {
            if (itemSecond.fileId === fileId) {
              const res = itemSecond.messages.filter((itemThird) => itemThird.id !== commentId);
              itemSecond.messages = res;
            }
          });
        }
      });
      state.error = null;
      state.loadingDelete = false;
    });
    builder.addCase(
      fetchCreatorDeleteComment.rejected,
      (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.loadingDelete = false;
      },
    );
    builder.addCase(fetchCreatorDeleteComment.pending, (state) => {
      state.loadingDelete = true;
    });
  },
});

export default interactionsCreatorSlice.reducer;
