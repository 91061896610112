import React, { useState } from 'react';
import { useAuth } from '@clerk/clerk-react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { instance } from '../../../../../../../../api/base/instance';
import { PinkLoader } from '../../../../../../../../shared/components/Loaders/PinkLoader/PinkLoader';
import { BaseSecondaryButton } from '../../../../../../../../shared/styles';
import { ErrorMessage } from '../../../../../../../../shared/components/styled';

export const UpdatePaymentMethodButton: React.FC = () => {
  const { t } = useTranslation(['settings', 'basicComponents']);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const { getToken } = useAuth();
  const updatePaymentMethod = async () => {
    setIsLoading(true);
    const token = await getToken();
    instance.get('/subscription/paymentMethod', { headers: { crossDomain: true, Authorization: `${token}` } })
      .then((res) => window.open(res.data.url))
      .catch((err) => setError(err?.response?.data?.message || 'Failed to open the updating page'))
      .finally(() => setIsLoading(false));
  };

  return (
    <Stack ml="20px" gap={1}>
      <BaseSecondaryButton
        color="black"
        type="button"
        fontWeight={600}
        onClick={updatePaymentMethod}
        style={{
          minWidth: '220px',
          width: '220px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {isLoading ? <PinkLoader size="xs" /> : t('updatePaymentMethod')}
      </BaseSecondaryButton>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </Stack>
  );
};
