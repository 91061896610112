/* eslint-disable */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { UploadingItem, FileRole, GalleryId, UploadUrlsResponseDto, ErrorItem, FileFormats, Variations } from '../../../types/gallery';
import { uploadFilesAPI } from '../../../../../api/gallery/gallery';
import { ErrorType } from '../../../../../shared/types';
import { ErrorCode } from '../../../types/types';
import { GetToken } from '@clerk/types';
import { GalleryFileBaseWithUrls } from '../../../../../shared/types/commonGallery';

interface GalleryState {
  count: number;
  uploading: { sectionId: string, files: UploadingItem[] }[];
  errorFiles: { sectionId: string, files: ErrorItem[] }[];
  files: { sectionId: string, files: GalleryFileBaseWithUrls[] }[];
  error: ErrorType;
  warning: string;
}

const initialState: GalleryState = {
  count: 0,
  uploading: [],
  files: [],
  errorFiles: [],
  error: null,
  warning: '',
};

const awsDomain = `${process.env.REACT_APP_BASE_MEDIA_URL}/`

export const getUploadFileUrl = createAsyncThunk<
  { sectionId: string, files: UploadUrlsResponseDto },
  { files: File[], sectionId: string, sectionName: string, galleryId?: GalleryId, getToken: GetToken, role: FileRole },
  { rejectValue: { message: string, status: string } }
>('gallery/getUploadFileUrl', async ({ files, sectionId, galleryId, getToken, role, sectionName }, thunkApi) => {
  let uploadingFiles: UploadingItem[] = [];

  try {
    const sendFiles = files.map((item) => {
      uploadingFiles.push({
        name: item.name,
        type: item.type,
      });
      return {
        name: item.name,
        type: item.type,
        size: item.size,
        role: role,
      }
    });

    thunkApi.dispatch(setUploadingFiles({ sectionId, files: uploadingFiles }));
    const token = (await getToken()) || '';
    const response = await uploadFilesAPI.getUploadFileUrl({
      files: sendFiles,
      galleryId: galleryId || '',
      sectionName,
      sectionId,
    }, token);

    return { sectionId, files: response.data };
  } catch (e: any) {
    thunkApi.dispatch(setErrorFiles({ sectionId, files: uploadingFiles }));

    return thunkApi.rejectWithValue({
      message: e.response.data.message || e.response.data.error || 'File wasn\'t uploaded',
      status: e.response.data.status || 'error',
    },
    );
  }
});

export const uploadFile = createAsyncThunk<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any,
  { file: File; url: string, sectionId: string, fileId: string, fileRole: FileRole, originalAwsKey: string },
  { rejectValue: string }
>('gallery/uploadFile', async ({ file, url, sectionId, fileId, fileRole, originalAwsKey }, thunkApi) => {
  try {
    const config = {
      headers: {
        'Content-Type': file.type,
        'x-amz-meta-fileRole': fileRole,
        'x-amz-meta-fileId': fileId,
      },
    };

    const response = await uploadFilesAPI.uploadFile(url, file, config);

    let fileUrl = '';
    if (file.type.includes('video')) {
      fileUrl = `${awsDomain}${fileId}/${Variations.Preview}.${FileFormats.Video}`;
    } else {
      fileUrl = `${awsDomain}${fileId}/${Variations.Full}.${FileFormats.Photo}`;
    }

    const uploadingFile: UploadingItem = {
      name: file.name,
      type: file.type,
    };

    await checkUploadingStatus(fileUrl, thunkApi, sectionId, uploadingFile, fileId, originalAwsKey);

    return response.data;
  } catch (err) {
    thunkApi.dispatch(setErrorFiles({ sectionId, files: [{
      name: file.name,
      type: file.type,
    }] }));
    return thunkApi.rejectWithValue('Error');
  }
});

export async function checkUploadingStatus(url: string, thunkApi: any, sectionId: string, file: UploadingItem, fileId: string, originalAwsKey: string): Promise<any> {
  return await new Promise((resolve, reject) => { 
    let cnt = 0
    let interval: any;
    interval = setInterval(async () => {
      const result = await fetch(url);
      cnt++;
      if (result.status === 200) {
        clearInterval(interval);
        thunkApi.dispatch(setUploadedFiles({ sectionId, data: {
          name: file.name,
          type: file.type,
          originalAwsKey,
        }, fileId }));
        resolve('200');
      } else if (cnt >= 100) {
        clearTimeout(interval);
        thunkApi.dispatch(setErrorFiles({ sectionId, files: [file] }));
        reject();
      }
    }, 3000);
  });
}

const uploadFilesSlice = createSlice({
  name: 'uploadFiles',
  initialState,
  reducers: {
    setErrorFiles(state, action: PayloadAction<{ sectionId: string, files: ErrorItem[] }>) {
      const { sectionId, files } = action.payload;
      const indexSectionUploading = state.uploading.findIndex((item) => item.sectionId === sectionId);
      const filtered = state.uploading[indexSectionUploading].files.filter((item) => !files.find((file) => file.name === item.name));

      state.uploading[indexSectionUploading].files = filtered;
      
      const indexSection = state.errorFiles.findIndex((item) => item.sectionId === sectionId);
      if (indexSection === -1) {
        state.errorFiles.push({ sectionId: sectionId, files });
      } else {
        state.errorFiles[indexSection].files = [...state.errorFiles[indexSection].files, ...files];
      }

      if (!action.payload.sectionId) {
        state.error = 'Failed to upload the file';
      }
      // state.warning = '';
    },
    setUploadingFiles(state, action: PayloadAction<{ sectionId: string, files: UploadingItem[] }>) {
      const indexSection = state.uploading.findIndex((item) => item.sectionId === action.payload.sectionId);
      if (indexSection === -1) {
        state.uploading.push({ sectionId: action.payload.sectionId, files: action.payload.files });
      } else {
        state.uploading[indexSection].files = [...state.uploading[indexSection].files, ...action.payload.files];
      }

      state.error = null;
      state.warning = '';
    },
    setUploadedFiles(state, action: PayloadAction<{ sectionId: string, data: { name: string, type: string, originalAwsKey: string }, fileId: string }>) {
      const { data, sectionId, fileId } = action.payload;
      const indexSection = state.uploading.findIndex((item) => item.sectionId === sectionId);
      const index = state.uploading[indexSection].files.findIndex((el) => (el.name === data.name));

      if (index !== -1) {
        const uploadingFile = state.uploading[indexSection].files.splice(index, 1);
      }

      if (!state.files.find((item) => item.sectionId === action.payload.sectionId)) {
        state.files.push({ sectionId, files: [] });
      }
      const filesIndexSection = state.files.findIndex((item) => item.sectionId === action.payload.sectionId)

      if (filesIndexSection !== -1) {
        if (data.type.includes('video')) {
          state.files[filesIndexSection].files.push({
            ...data,
            id: fileId,
            url: `${awsDomain}${fileId}/${Variations.Preview}.${FileFormats.Video}`,
            urlFullSize: `${awsDomain}${fileId}/${Variations.Full}.${FileFormats.Video}`,
            urlWatermarked: `${awsDomain}${fileId}/${Variations.Watermarked}.${FileFormats.Video}`,
          });
        } else {
          state.files[filesIndexSection].files.push({
            ...data,
            id: fileId,
            url: `${awsDomain}${fileId}/${Variations.Full}.${FileFormats.Photo}`,
            urlWatermarked: `${awsDomain}${fileId}/${Variations.Watermarked}.${FileFormats.Photo}`,
          });
        }
      }  

      state.error = null;
    },
    resetUploadingFiles(state) {
      state.uploading = [];
    },
    resetErrorFiles(state) {
      state.errorFiles = [];
    },
    resetFiles(state) {
      state.files = [];
    },
    unsetError(state) {
      state.error = null;
    },
    unsetWarning(state) {
      state.warning = '';
    },
    deleteFromErrorFiles(state, action: PayloadAction<{ files: (File | ErrorItem)[], sectionId: string }>) {
      const { sectionId, files } = action.payload;
      const indexSection = state.errorFiles.findIndex((item) => item.sectionId === sectionId);
      const filtered = state.errorFiles[indexSection].files.filter((item) => !files.find((file) => file.name === item.name));

      if (indexSection !== -1) {
        state.errorFiles[indexSection].files = filtered;

        if (!state.errorFiles[indexSection].files.length) {
          state.errorFiles.splice(indexSection, 1);
        }
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getUploadFileUrl.fulfilled, (state, action) => {
      state.error = null;
      state.warning = '';
    });

    builder.addCase(getUploadFileUrl.rejected, (state, action) => {
      if (action.payload?.status === ErrorCode.Warning) {
        state.warning = action.payload.message;
      } else {
        state.error = action.payload?.message || 'Error';
      }
    });

    builder.addCase(uploadFile.fulfilled, (state, action) => {
      state.error = null;
    });
  }
});

export const {
  resetUploadingFiles,
  resetFiles,
  unsetError,
  deleteFromErrorFiles,
  resetErrorFiles,
  unsetWarning,
  setUploadingFiles,
  setUploadedFiles,
  setErrorFiles,
} = uploadFilesSlice.actions;

export default uploadFilesSlice.reducer;
