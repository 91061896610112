/* eslint-disable max-len */
import { Axios, AxiosResponse } from 'axios';
import { CreatorProfilePut, SignUp } from '../../shared/types';
import { instance } from '../base/instance';
import { GetOrdersResponse } from '../../modules/Creator/types/sales';

const creatorPath = 'creators/';
const creatorAuth = 'auth/';
const settings = 'settings/';
const gallery = 'gallery/';

export const creator = {
  // is Auth
  redirectToOnboarding: (token: string) => instance.get(`${creatorPath}onboarding`, { headers: { crossDomain: true, Authorization: `${token}` } }),
  getCurrentCreator: (token: string) => instance.get(`${creatorPath}profile`, { headers: { crossDomain: true, Authorization: `${token}` } }),
  updateProfile: (data: CreatorProfilePut, token: string): Promise<AxiosResponse<any>> => instance.put(`${creatorPath}${settings}`, data, { headers: { crossDomain: true, Authorization: `${token}` } }),

  // is unauth
  checkClerkUser: (data: SignUp & { timezone: string, email: string }): Promise<AxiosResponse<any>> => instance.put(`${creatorPath}clerk`, data),

  // move/moved to Clerk func
  checkPassword: (password: string) => instance.post(`${creatorAuth}settings/checkpw`, { password }),
};

export const orders = {
  getOrdersByCreatorId: (token: string, page: number, limit: number): Promise<AxiosResponse<GetOrdersResponse>> => instance.get('order', { headers: { crossDomain: true, Authorization: `${token}` }, params: { limit, page } }),
  getOrdersByGalleryId: (token: string, page: number, limit: number, galleryId: string): Promise<AxiosResponse<GetOrdersResponse>> => instance.get(`order/${galleryId}`, { headers: { crossDomain: true, Authorization: `${token}` }, params: { limit, page } }),
  resendDownloadLink: (orderId: string, token: string):Promise<AxiosResponse<void>> => instance.get(`order/resend-email/${orderId}`, { headers: { crossDomain: true, Authorization: `${token}` } }),
  // getOrdersByGalleryId: (token: string, galleryId: string) => instance.get(`order/${galleryId}`, { headers: { crossDomain: true, Authorization: `${token}` } }),
};
