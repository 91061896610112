import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { clientOrder } from '../../../../api/client/clientOrder';
import { ClientOrder } from '../../types/types';

export const fetchClientOrder = createAsyncThunk(
  'clientOrder/fetchClientOrder',
  async (orderId: string, thunkApi) => {
    try {
      const response = await clientOrder.getOrder(orderId);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue('Error');
    }
  },
);

type initialState = {
  order: ClientOrder | null;
  loading: boolean;
  error: string;
};

export const initialState: initialState = {
  order: null,
  loading: false,
  error: '',
};

const orderSlice = createSlice({
  name: 'orderSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchClientOrder.fulfilled,
      (state, action: PayloadAction<ClientOrder>) => {
        state.error = '';
        state.loading = false;
        state.order = action.payload;
      },
    );
    builder.addCase(
      fetchClientOrder.rejected,
      (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.loading = false;
      },
    );
    builder.addCase(fetchClientOrder.pending, (state) => {
      state.loading = true;
    });
  },
});

export default orderSlice.reducer;
