/* eslint-disable max-len */
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  FileBySection,
  FileBySectionPut,
} from '../../../../shared/types/interactions/interactions';
import { clientInteractions } from '../../../../api/client/interactions';
import { ClientGalleryFile } from '../../../../shared/types';
import { cartHandle, likeHandle } from '../../../GalleryView/redux/gallery/gallerySlice';
import { cartHandle as cartHandleClient, likeHandle as likeHandleClient } from '../signIn/signInClientSlice';
import { fileAddedToCart, fileFavourited } from '../../../GalleryView/redux/gallery/swiperSlice';

export const fetchGetClientInteractions = createAsyncThunk(
  'interactionsClient/fetchGetClientInteractions',
  async (galleryId: string, thunkApi) => {
    try {
      const response = await clientInteractions.getInteractions(galleryId);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue('Error');
    }
  },
);

export const fetchAddToCart = createAsyncThunk(
  'interactionsClient/fetchAddToCart',
  async (
    data: {
      interactions: {
        cartFiles?: FileBySectionPut[];
        limitedFreeCartFiles?: FileBySectionPut[];
      };
      updateStateData: {
        cartFiles: FileBySection[];
        limitedFreeCartFiles: Array<FileBySection>;
      };
    },
    thunkApi,
  ) => {
    try {
      const state: any = thunkApi.getState() as any;
      const { _id, galleryId, email } = state.interactionsClient.interaction;
      const response = await clientInteractions.modifyInteractions(_id, {
        ...data.interactions,
        id: _id,
        galleryId,
        email,
      });
      thunkApi.dispatch(
        fileAddedToCart({
          cartFiles: data.interactions.cartFiles || [],
          limitedFreeCartFiles: data.interactions.limitedFreeCartFiles || [],
          type: 'add',
        }),
      );
      thunkApi.dispatch(
        cartHandle({
          cartFiles: data.interactions.cartFiles || [],
          limitedFreeCartFiles: data.interactions.limitedFreeCartFiles || [],
          type: 'add',
        }),
      );
      thunkApi.dispatch(cartHandleClient({
        cartFiles: data.interactions.cartFiles || [],
        limitedFreeCartFiles: data.interactions.limitedFreeCartFiles || [],
        type: 'add',
      }));
      return data.updateStateData;
    } catch (error) {
      return thunkApi.rejectWithValue('Error');
    }
  },
);

export const getCartDataRemove = (
  limited: ClientGalleryFile[],
  cart: ClientGalleryFile[],
) => {
  const limitedFreeCartFiles: FileBySectionPut[] = [];
  const cartFiles: FileBySectionPut[] = [];

  cart.forEach((file) => {
    cartFiles.push({ sectionId: file.sectionId || '', file: file.id });
  });
  limited.forEach((file) => {
    limitedFreeCartFiles.push({
      sectionId: file.sectionId || '',
      file: file.id,
    });
  });
  return {
    cartFiles,
    limitedFreeCartFiles,
  };
};

export const fetchRemoveFromCart = createAsyncThunk(
  'interactionsClient/fetchRemoveFromCart',
  async (
    data: {
      cartFiles: ClientGalleryFile[];
      limitedFreeCartFiles: ClientGalleryFile[];
      notIncreaseFreeFiles?: boolean,
    },
    thunkApi,
  ) => {
    try {
      const filesReq = getCartDataRemove(
        data.limitedFreeCartFiles,
        data.cartFiles,
      );
      const cartIds = data.cartFiles.map((item) => item.id);
      const freeIds = data.limitedFreeCartFiles.map((item) => item.id);

      const state: any = thunkApi.getState() as any;
      const { _id, galleryId, email } = state.interactionsClient.interaction;
      const response = await clientInteractions.deleteInteractionItem(_id, {
        cartFiles: cartIds,
        limitedFreeCartFiles: freeIds,
        galleryId,
        email,
      });
      thunkApi.dispatch(
        fileAddedToCart({
          cartFiles: filesReq.cartFiles,
          limitedFreeCartFiles: filesReq.limitedFreeCartFiles,
          type: 'delete',
        }),
      );
      thunkApi.dispatch(
        cartHandle({
          cartFiles: filesReq.cartFiles,
          limitedFreeCartFiles: filesReq.limitedFreeCartFiles,
          type: 'delete',
          notIncreaseFreeFiles: data.notIncreaseFreeFiles,
        }),
      );
      thunkApi.dispatch(
        cartHandleClient({
          cartFiles: filesReq.cartFiles,
          limitedFreeCartFiles: filesReq.limitedFreeCartFiles,
          type: 'delete',
          notIncreaseFreeFiles: data.notIncreaseFreeFiles,
        }),
      );

      return {
        cartFiles: cartIds || [],
        limitedFreeCartFiles: freeIds || [],
      };
    } catch (error) {
      return thunkApi.rejectWithValue('Error');
    }
  },
);

export const fetchAddToFav = createAsyncThunk(
  'interactionsClient/fetchAddToFav',
  async (
    data: {
      favs: FileBySectionPut[];
      updateStateData: FileBySection[];
    },
    thunkApi,
  ) => {
    try {
      const state: any = thunkApi.getState() as any;
      const { _id, galleryId, email } = state.interactionsClient.interaction;

      const response = await clientInteractions.modifyInteractions(_id, {
        favourites: data.favs,
        id: _id,
        galleryId,
        email,
      });
      thunkApi.dispatch(fileFavourited(data.favs));
      thunkApi.dispatch(likeHandle(data.favs));
      thunkApi.dispatch(likeHandleClient(data.favs));
      return data.updateStateData;
    } catch (error) {
      return thunkApi.rejectWithValue('Error');
    }
  },
);

export const getFavDataRemove = (fav: ClientGalleryFile[]) => {
  const res: FileBySectionPut[] = [];

  fav.forEach((file) => {
    res.push({ sectionId: file.sectionId || '', file: file.id });
  });
  return res;
};

export const fetchRemoveFromFav = createAsyncThunk(
  'interactionsClient/fetchRemoveFromFav',
  async (favs: ClientGalleryFile[], thunkApi) => {
    try {
      const allIds = favs.map((item) => item.id);

      const filesReq = getFavDataRemove(favs);
      const state: any = thunkApi.getState() as any;
      const { _id, galleryId, email } = state.interactionsClient.interaction;
      const response = await clientInteractions.deleteInteractionItem(_id, {
        favourites: allIds,
        galleryId,
        email,
      });
      thunkApi.dispatch(fileFavourited(filesReq));
      thunkApi.dispatch(likeHandle(filesReq));
      thunkApi.dispatch(likeHandleClient(filesReq));
      return filesReq;
    } catch (error) {
      return thunkApi.rejectWithValue('Error');
    }
  },
);

export const fetchAddToDownloads = createAsyncThunk(
  'interactionsClient/fetchAddToDownloads',
  async (
    data: {
      downloads: FileBySectionPut[];
    },
    thunkApi,
  ) => {
    try {
      const state: any = thunkApi.getState() as any;
      const { _id, galleryId, email } = state.interactionsClient.interaction;
      const response = await clientInteractions.modifyInteractions(_id, {
        downloads: data.downloads,
        id: _id,
        galleryId,
        email,
      });
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue('Falied to add to downloaded files');
    }
  },
);
