/* eslint-disable */
import { useState } from 'react';
import { useCreatorAppDispatch, useTypedSelectorCreator } from '../../../shared/hooks/useTypedSelector';
import {
  getUploadFileUrl,
  uploadFile,
  deleteFromErrorFiles,
  resetFiles,
  setErrorFiles,
} from '../redux/gallery/uploadFiles/uploadFilesSlice';
import { ErrorItem, FileRole, GalleryId, UploadUrlsResponseDto, UploadingItem } from '../types/gallery';
import { useAuth } from '@clerk/clerk-react';
import { CreatorGalleryFile } from '../../../shared/types/commonGallery';

const QUEUE_NUMBER = 50;

function spliceIntoChunks(arr: UploadUrlsResponseDto) {
  const res = [];
  while (arr.length > 0) {
      const chunk = arr.splice(0, QUEUE_NUMBER);
      res.push(chunk);
  }
  return res;
}

const transformMOV = (file: File) => {
  let lcFile = file;
  const splitedName = file.name.split('.');
  if (splitedName[splitedName?.length - 1] === 'MOV') {
    return new File([file], `${splitedName.slice(0, splitedName.length - 1)}.mov`, {type: 'video/quicktime'});
  }
  return lcFile;
};

export type OnUploadProps = {
  files: File[],
  galleryId: GalleryId,
  fileRole: FileRole,
  sectionId?: string,
  anchorFileId?: string | null,
  existedFiles?: CreatorGalleryFile[];
  sectionName?: string; 
}

type ReturnObj = {
  files: File[];
  stateFiles: any;
  uploading: UploadingItem[];
  errorFiles: ErrorItem[];
  onUpload: (props: OnUploadProps) => void;
  setFiles: (files: File[]) => void;
  error: string | null;
  warning: string;
  isDuplicate: boolean;
};

export const useUploadFiles = (sectionId = ''): ReturnObj => {
  const [uploadFiles, setFiles] = useState<File[]>([]);
  const [isDuplicate, setIsDuplicate] = useState<boolean>(false);
  const uploading = useTypedSelectorCreator((state) => state.uploadFiles.uploading);
  const stateFiles = useTypedSelectorCreator((state) => state.uploadFiles.files);
  const errorFiles = useTypedSelectorCreator((state) => state.uploadFiles.errorFiles);
  const error = useTypedSelectorCreator((state) => state.uploadFiles.error);
  const warning = useTypedSelectorCreator((state) => state.uploadFiles.warning);
  const currentUploading = uploading.find((item) => item.sectionId === sectionId);
  const { getToken } = useAuth();

  const dispatch = useCreatorAppDispatch();

  const onUpload = async ({files, galleryId, fileRole, anchorFileId = null, sectionId = '', sectionName = '', existedFiles = [] }: OnUploadProps) => {
    if (files && files.length) {
      setIsDuplicate(false);
      let lcFiles = files.map((item) => transformMOV(item));
      const uploadingCurrent = uploading.find((item) => item.sectionId === sectionId);
      const errorCurrent = errorFiles.find((item) => item.sectionId === sectionId);

      if (uploadingCurrent) {
        lcFiles = lcFiles.filter((item) => {
          const existedFile = uploadingCurrent.files.find((file) => file.name === item.name);
          if (existedFile) {
            setIsDuplicate(true);
          }
          return !existedFile;
        });
      }
      if (errorCurrent) {
        lcFiles = lcFiles.filter((item) => {
          const existedFile = errorCurrent.files.find((file) => file.name === item.name);
          if (existedFile) {
            setIsDuplicate(true);
          }
          return !existedFile;
        });
      }
      if (existedFiles.length) {
        lcFiles = lcFiles.filter((item) => {
          const existedFile = existedFiles.find((file) => file.name === item.name);
          if (existedFile) {
            setIsDuplicate(true);
          }
          return !existedFile;
        });
      }

      if (lcFiles) {
        setFiles(prev => [...prev, ...lcFiles]);

      try {
        if (errorFiles.length) {
          dispatch(deleteFromErrorFiles({ files: lcFiles, sectionId }));
        }
        dispatch(resetFiles());
        const urlResponse = (await dispatch(
          getUploadFileUrl({ files: lcFiles, sectionId: sectionId, sectionName, galleryId, getToken, role: fileRole }),
        )) as any;
        if (urlResponse.payload && !urlResponse.payload.error) {
          // проверить не пришла ли ошибка, приходят данные от облака
          const filesToUpload = spliceIntoChunks(urlResponse.payload.files as UploadUrlsResponseDto);
          const uploadFilesQueueFunc = async (arr: UploadUrlsResponseDto) => {
            const promises = arr.map(async (item) => {
              const name = Object.keys(item);
              const fileLocal = lcFiles.find((file) => file.name === name[0]);
            
              if (fileLocal && item[name[0]].errorMessage) {
                dispatch(setErrorFiles({
                  files: [{ name: name[0], type: fileLocal.type, errorMessage: item[name[0]].errorMessage }], sectionId,
                }));
              }
              if (fileLocal && item[name[0]].id && item[name[0]].url && !item[name[0]].errorMessage) {
                await dispatch(uploadFile({
                  file: fileLocal, url: item[name[0]].url, sectionId: sectionId, fileId: item[name[0]].id, fileRole, originalAwsKey: item[name[0]].originalAwsKey,
                }));
              }
            });
            await Promise.all(promises);
          }
          for (let i = 0; i < filesToUpload.length; i++) {
            await uploadFilesQueueFunc(filesToUpload[i]);
          }
        }
      } catch (e) {
        console.log(e);
      }

      }
    }
  };
  const resUploading = currentUploading?.files || [];
  const resStateFiles = stateFiles.find((item) => item.sectionId === sectionId)?.files || [];
  const resErrorFiles = errorFiles.find((item) => item.sectionId === sectionId)?.files || [];

  return {
    files: uploadFiles,
    uploading: resUploading,
    onUpload,
    setFiles,
    stateFiles: resStateFiles,
    error,
    errorFiles: resErrorFiles,
    warning,
    isDuplicate,
  };
};
