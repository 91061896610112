import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { ClientGalleryFile, FileBySectionPut, GalleryIdea } from '../../../../shared/types';

export type SwiperFiles = {
  files: (ClientGalleryFile | GalleryIdea)[];
  currentFile: ClientGalleryFile | GalleryIdea | null;
}

type SwiperState = {
  isOpen: boolean;
  currentFile: ClientGalleryFile | GalleryIdea | null;
} & SwiperFiles;

const initialState: SwiperState = { files: [], isOpen: false, currentFile: null };

const swiperSlice = createSlice({
  name: 'swiper',
  initialState,
  reducers: {
    fileAddedToCart(state, action: PayloadAction<{
      cartFiles: FileBySectionPut[];
      limitedFreeCartFiles: Array<FileBySectionPut>;
      type: 'delete' | 'add';
  }>) {
      const { cartFiles, limitedFreeCartFiles, type } = action.payload;
      const cartFileIds = cartFiles.map((item) => item.file);
      const freeFilesIds = limitedFreeCartFiles.map((item) => item.file);
      state.files.forEach((file) => {
        if (type === 'add') {
          if (cartFileIds.includes(file.id)) {
            file.isInCart = true;
          }
          if (freeFilesIds.includes(file.id)) {
            file.isInCart = true;
          }
        } else {
          if (cartFileIds.includes(file.id)) {
            file.isInCart = false;
          }
          if (freeFilesIds.includes(file.id)) {
            file.isInCart = false;
          }
        }
      });
    },
    fileFavourited(state, action: PayloadAction<FileBySectionPut[]>) {
      const favs = action.payload;
      const favsIds = favs.map((item) => item.file);
      state.files.forEach((file) => {
        if (favsIds.includes(file.id)) {
          file.isFavourited = !file.isFavourited;
        }
      });
    },
    openSwiper(state, action: PayloadAction<SwiperFiles>) {
      state.files = action.payload.files;
      state.isOpen = true;
      state.currentFile = action.payload.currentFile;
    },
    closeSwiper(state) {
      state.isOpen = false;
      state.currentFile = null;
    },
  },
});

export const {
  openSwiper, closeSwiper, fileAddedToCart, fileFavourited,
} = swiperSlice.actions;
export default swiperSlice.reducer;
