import React, { ReactNode, useState } from 'react';
import { AxiosResponse } from 'axios';
import { downloadFiles } from '../../../../api/client/clientGallery';
import { CustomAlert } from '../../../../shared/components/CustomAlert/CustomAlert';
import { useTypedSelectorClient } from '../../../../shared/hooks/useTypedSelector';

type ReturnObj = {
  handleDownloadSingleFile: (
    fileId: string,
    fileName: string,
    sectionId: string
  ) => void;
  downloadErrorAlert: ReactNode;
  isLoadingFile: string[];
};

export const useFreeFilesDownload = (): ReturnObj => {
  const [error, setError] = useState('');
  const [isLoadingFile, setIsLoadingFile] = useState<string[]>([]);
  const interaction = useTypedSelectorClient(
    (state) => state.interactionsClient?.interaction,
  );

  const handleDownloadSingleFile = async (
    fileId: string,
    fileName: string,
    sectionId: string,
  ) => {
    setIsLoadingFile((prev) => [...prev, fileId]);
    let res: AxiosResponse<any> | null = null;
    try {
      res = await downloadFiles.getDownloadSingleFileUrl(
        interaction._id,
        interaction.galleryId,
        sectionId,
        fileId,
      );
    } catch (e) {
      setIsLoadingFile((prev) => prev.filter((item) => item !== fileId));
      setError('Download process failed. Please, try again later');
    }
    const url = res?.data;
    if (url) {
      fetch(url)
        .then((res) => {
          if (!res.ok) {
            setIsLoadingFile((prev) => prev.filter((item) => item !== fileId));
            setError('Download process failed. Please, try again later');
          }
          return res.blob();
        })
        .then((file) => {
          const tUrl = URL.createObjectURL(file);
          const tmp1 = document.createElement('a');
          tmp1.href = tUrl;
          tmp1.download = fileName;
          document.body.appendChild(tmp1);
          tmp1.click();
          URL.revokeObjectURL(tUrl);
          tmp1.remove();
        })
        .catch(() => {
          setError('Download process failed. Please, try again later');
        })
        .finally(() => setIsLoadingFile((prev) => prev.filter((item) => item !== fileId)));
    }
  };

  return {
    handleDownloadSingleFile,
    isLoadingFile,
    downloadErrorAlert: (
      <CustomAlert
        isOpenWindow={!!error}
        message={error || ''}
        type="error"
        title="Oops..."
        onClose={() => setError('')}
        onClick={() => setError('')}
      />
    ),
  };
};
