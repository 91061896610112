import React, { ReactNode } from 'react';
import {
  isDocument, isIdea, isImage, isVideo,
} from '..';
import { ImageContainer } from '../../components/Files/ImageContainer/ImageContainer';
import { VideoContainer } from '../../components/Files/VideoContainer/VideoContainer';
import { BasicDocumentCard } from '../../components/Files/BasicDocumentCard/BasicDocumentCard';
import { FileLoader } from '../../components/Loaders/FileLoader/FileLoader';
import { FileLoaderWrapper } from '../../components/Loaders/FileLoader/styled';
import { ProgressLoader } from '../../components/Loaders/ProgressLoader/ProgressLoader';
import {
  ClientGalleryFile,
  ClientOrCreatorGalleryFile,
  CreatorGalleryFile,
  GalleryFileBase,
  GalleryFileBaseWithUrls,
  GalleryIdea,
} from '../../types';
import { getFileUrls } from './setFileUrls';

type ImageAndVideoProps = {
  autoPlay?: boolean;
  widthStretch?: boolean;
};

type FileInContainerProps = {
  file: ClientOrCreatorGalleryFile | null;
  imageAndVideoProps?: ImageAndVideoProps;
  tryingVideoThumbnailUrl?: string;
  isLoading?: boolean;
  watermarked?: boolean;
  isLoadingWithProgress?: boolean;
  progress?: number;
  littlePlayButton?: boolean;
  hasDefaultPlayBtn?: boolean;
};

export const getFileInContainer = ({
  file,
  imageAndVideoProps,
  tryingVideoThumbnailUrl,
  isLoading,
  watermarked,
  isLoadingWithProgress,
  progress,
  littlePlayButton,
  hasDefaultPlayBtn,
}: FileInContainerProps): ReactNode | null => {
  if (isLoadingWithProgress) {
    return (
      <FileLoaderWrapper isLittle={false}>
        <ProgressLoader value={progress || 0} />
      </FileLoaderWrapper>
    );
  }
  if (isLoading) {
    return <FileLoader isLittle isLoading />;
  }
  if (isDocument(file) && file) {
    return <BasicDocumentCard isSmall file={file} />;
  }
  if (isImage(file)) {
    return (
      <ImageContainer
        imageUrl={watermarked ? file?.urlWatermarked || '' : file?.url || ''}
        widthStretch={imageAndVideoProps?.widthStretch}
      />
    );
  }
  if (isVideo(file)) {
    return (
      <VideoContainer
        hasDefaultPlayBtn={hasDefaultPlayBtn}
        videoThumbnail={
          tryingVideoThumbnailUrl || file?.videoThumbnailFile?.url
        }
        videoUrl={file?.url || ''}
        widthStretch={imageAndVideoProps?.widthStretch}
        autoPlay={imageAndVideoProps?.autoPlay}
        littlePlayButton={littlePlayButton}
      />
    );
  }
  return null;
};

export const getIdeaCoverOrFile = (
  fileOrIdea: ClientGalleryFile | CreatorGalleryFile | GalleryIdea,
): ClientGalleryFile | CreatorGalleryFile => {
  if (isIdea(fileOrIdea)) {
    return (fileOrIdea as GalleryIdea).cover!;
  }
  return getFileUrls<GalleryFileBase>(fileOrIdea);
};
