import React, { useCallback, useState } from 'react';
import { AxiosResponse } from 'axios';
import { useAuth } from '@clerk/clerk-react';
import { orders } from '../../../../../../../../../../api/creator/creator';

type ReturnObj = {
  handleResendDownloadLink: (orderId: string) => void;
  isLoading: boolean;
  isSuccess: boolean;
  error: string;
};

export const useResendDownloadLink = (): ReturnObj => {
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const { getToken } = useAuth();

  const handleResendDownloadLink = useCallback(async (
    orderId: string,
  ) => {
    setIsSuccess(false);
    setError('');
    setIsLoading(true);
    try {
      const token = await getToken();
      if (token) {
        await orders.resendDownloadLink(orderId, token);
        setIsSuccess(true);
      }
      setIsLoading(false);
      setTimeout(() => setIsSuccess(false), 3000);
    } catch (e) {
      setIsSuccess(false);
      setIsLoading(false);
      setError('Resending process failed. Please, try again later');
    }
  }, [getToken]);

  return {
    handleResendDownloadLink,
    isLoading,
    isSuccess,
    error,
  };
};
