import React, { ReactElement, useState, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Fade, Popper } from '@mui/material';
import { TickIcon } from '../../icons';
import { LittleBlackText } from '../../styles';
import { colorGreyLight } from '../../../constants/styles';

type ReturnObj = {
  handleCopy: (e: MouseEvent<HTMLButtonElement>, value: string) => void;
  isCopied: boolean;
  wasCopiedText: ReactElement;
  tooltip: ReactElement;
}

export const useCopyValue = (): ReturnObj => {
  const { t } = useTranslation('basicComponents');
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = async (e: MouseEvent<HTMLButtonElement>, value: string) => {
    await navigator.clipboard.writeText(value);
    setAnchorEl(e.currentTarget || e.target);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000);
  };

  const wasCopiedText = (
    <>
      <div style={{ marginRight: '10px', display: 'flex' }}><TickIcon sx={{ width: '16px', height: '16px' }} /></div>
      <LittleBlackText fontWeight={500}>{t('copied')}</LittleBlackText>
    </>
  );

  const tooltip = (
    <Popper
      open={isCopied}
      anchorEl={anchorEl}
      placement="right"
      transition
      disablePortal
      id="copy-button"
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <Box sx={{ marginLeft: '20px', background: colorGreyLight }} display="flex" flexDirection="row" alignItems="center">
            {wasCopiedText}
          </Box>
        </Fade>
      )}
    </Popper>
  );

  return ({
    handleCopy,
    isCopied,
    wasCopiedText,
    tooltip,
  });
};
