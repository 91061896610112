import { useCallback, useState } from 'react';
import { useGalleryAppDispatch } from '../../../../shared/hooks/useTypedSelector';
import { fetchAddToFav, fetchRemoveFromFav } from '../../../Client/redux/interactions/interactionsClientThunk';
import { ClientGalleryFile } from '../../../../shared/types';
import { FileBySection, FileBySectionPut } from '../../../../shared/types/interactions/interactions';

type ReturnObj = {
  handleLike: (fileId: ClientGalleryFile) => void;
  isLoadingFav: boolean;
};

export const useLike = (isFileLiked: boolean): ReturnObj => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useGalleryAppDispatch();

  const handleLike = useCallback(async (file: ClientGalleryFile) => {
    const req: FileBySectionPut = {
      sectionId: file.sectionId || '',
      file: file.id,
    };
    const update: FileBySection = {
      sectionId: file.sectionId || '',
      file,
    };
    setIsLoading(true);
    if (isFileLiked || file.isFavourited) {
      await dispatch(fetchRemoveFromFav([file]));
    } else {
      await dispatch(
        fetchAddToFav({ favs: [req], updateStateData: [update] }),
      );
    }
    setIsLoading(false);
  }, [isFileLiked, dispatch]);

  return {
    isLoadingFav: isLoading,
    handleLike,
  };
};
