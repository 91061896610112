/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/no-extraneous-dependencies */
// @ts-ignore
import { Parser } from '@json2csv/plainjs';
// @ts-ignore
import { saveAs } from 'file-saver';
import mime from 'mime';
import { Order, OrderedFile } from '../types/sales';

export const makeCsvFile: (content: string) => Blob = (content) => {
  const result = `\ufeff${content}`;
  const fExt = 'csv';
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const mimeType = mime.getType(fExt);
  return new Blob([result], {
    type: `${mimeType};charset=utf-8`,
  });
};

export const downloadAs: (
    content: string,
    fileName?: string,
  ) => void = (content, fileName = 'Summary') => {
    const fName = fileName;
    const fExt = 'csv';
    saveAs(makeCsvFile(content), `${fName}.${fExt}`);
  };

const transformFilesData = (order: Order) => {
  const files = order.files.map((item) => ({
    'Gallery section': item.sectionName,
    'File name': item.name,
    // 'Download status': item.downloaded ? 'Downloaded' : 'Not downloaded',
    // 'Disable downloads': item.disableDownloads ? 'ON' : 'OFF',
    'Within free file limit': 'NO',
    'Instantly downloadable': item.instantlyDownloadable ? 'YES' : 'NO',
    Price: `$${Number(item.price) / 100}`,
  }));
  const freeFiles = order.limitedFreeFiles.map((item) => ({
    'Gallery section': item.sectionName,
    'File name': item.name,
    // 'Download status': item.downloaded ? 'Downloaded' : 'Not downloaded',
    // 'Disable downloads': item.disableDownloads ? 'ON' : 'OFF',
    'Within free file limit': 'YES',
    'Instantly downloadable': item.instantlyDownloadable ? 'YES' : 'NO',
    Price: `$${Number(item.price) / 100}`,
  }));
  return [...files, ...freeFiles];
};

export const getCSV = (order: Order) => {
  try {
    const parser = new Parser();
    const csv = parser.parse(transformFilesData(order));
    return csv;
  } catch (err: any) {
    console.error(err);
    return err.message;
  }
};
