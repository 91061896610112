import styled from '@emotion/styled';
import * as variables from '../../../../../../../../constants/styles';
import { MediumBlackText } from '../../../../../../../../shared/styles';
import { BaseColors } from '../../../../../../../../shared/types';
import { getBaseColor } from '../../../../../../../../shared/utils/style/getBaseColor';
import { theme } from '../../../../../../../../shared/components/GlobalContainer/styled';

export const PaymentSetupWindowContainer = styled.div`
  background: ${variables.colorGreyLight};
  border-radius: 10px;
  padding: 30px 50px 40px;
  width: 100%;
  height: 465px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const PaymentSetupFormWrapper = styled.div`
  height: 100%;

  & > form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
`;

export const PaymentsConteiner = styled.div`
  margin-top: 60px;
`;

export const DotsIconElement = styled.ul`
  color: white;
  font-size: 16px;
  border: none;
  cursor: pointer;
  width: fit-content;
  transition: 0.2s ease-in-out;
  padding: 0px;
  margin: 0px;

  & li {
    background: none repeat scroll 0 0 ${variables.colorBtnSecondary};
    height: 5px;
    width: 5px;
    line-height: 0;
    list-style: none outside none;
    vertical-align: top;
    border-radius: 50%;
    pointer-events: none;
    transition: 0.2s ease-in-out;
    padding: 0px;
    margin: 0px 9px;

    &:not(:first-of-type) {
      margin-top: 3px;
    }
  }
`;

export const DotsMenuContainer = styled.div`
  position: absolute;
  top: 15px;
  right: 13px;
`;

export const CardInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & svg {
    width: 45px;
    height: 45px;
    margin-right: 8px;
  }
`;

export const PriceWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  & > div {
    width: fit-content; 
  }
`;

export const PaymentBillingInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
`;

export const PaymentMethodUpdateForm = styled.form`
  display: flex;
  flex-direction: column;
`;

export const PaymentMethodUpdateInputWrp = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > div {
    &:first-of-type {
      margin-right: 20px;
    }  
  }
`;

export const DotsMenuButton = styled.button`
  font-size: 16px;
  width: 100%;
  padding: 6px 16px 6px;
  text-align: start;
`;

export const SubscriptionInfoContainer = styled(PaymentSetupWindowContainer)`
  height: 365px;
`;

export const SubscriptionInfoWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const SubscriptionInfoBtn = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const CancelRenewalWindowContainer = styled.div`
  background: ${variables.colorGreyLight};
  border-radius: 10px;
  padding: 30px 80px 40px;
  width: 100%;
  height: fit-content;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CancelRenewalTooltip = styled.div`
  font-size: 14px;
  line-height: 150%;
  width: 100%;
  background: #EBEBEB;
  padding: 16px 26px;
  display: flex;
  box-sizing: border-box;
  border-radius: 9px 9px 9px 9px;
  flex-direction: column;
  border: 1px solid ${variables.colorError};
  margin-bottom: 26px;
`;

export const CancelRenewalListItem = styled.div`
  padding: 0px 0px 8px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;

  & svg {
    color: ${variables.colorError};
    margin-right: 5px;
  }
`;

export const CancelRenewalCheckbox = styled.div`
  padding: 16px 0px 0px 0px; 
`;

export const CancelTooltipTitle = styled(MediumBlackText)`
  display: flex;
  margin-bottom: 8px;
  align-items: center;
  
  & svg {
    color: ${variables.colorError};
    margin-right: 5px;
  }
`;

export const CancelRenewalButton = styled.button<{ color?: BaseColors }>`
  background: ${variables.colorGreyLight};
  text-transform: uppercase;
  font-weight: 600;
  line-height: 150%;
  font-size: 16px;
  color: ${({ color }) => getBaseColor(color || 'secondary')};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 13px 28px;
  height: fit-content;
  white-space: pre;
  transition: all 0.1s ease-in-out;
  margin-left: 20px;

  &:hover {
    transition: all 0.1s ease-in-out;
    color: ${variables.textGrey};
  }

  &:active {
    transition: all 0.1s ease-in-out;
  }

  &:disabled {
    color: ${variables.colorBleak};
    cursor: auto;
    transition: all 0.2s ease-in-out;
  }
`;
